@import "../../variables";

footer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .footer-top {
        background-image: url('../../assets/waves-4.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        height: 7.1vw;
        transform: translateY(.3vw);
        width: 100%;
        
        @media screen and (max-width: 575px) {
            height: 35vw;
            background-image: url('../../assets/waves-4_mobile.svg');
        }
        @media screen and (min-width: $largeSize) {
            height: 100px;
            width: 1400px;
        }
    }
    .footer-body__wrapper {
        background: $yellowColor;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 21.3vw;
        width: 100%;
        @media screen and (min-width: $mobileSize) {
            padding-bottom: 6vw;
            padding-top: 4vw;
        }
        @media screen and (min-width: $largeSize) {
            padding-bottom: 85px;
            padding-top: 57px;
            width: 1400px;
        }
    }
    .footer-body {
        background: $yellowColor;
        width: 88.2vw;
        z-index: 1;
        align-self: center;
        @media screen and (min-width: $mobileSize) {
            flex-direction: row;
            flex-wrap: nowrap;
            display: flex;
            justify-content: space-between;
            width: 81.1vw;
        }
        @media screen and (min-width: $largeSize) {
            width: 1136px;
        }
        button {
            background-color: transparent;
            cursor: pointer;
        }
        .location-link {
            // margin-right: 3.6vw;
            display: flex;
            align-items: center;
            @media screen and (min-width: $largeSize) {
                // margin-right: 51px;
            }
            &:hover {
                opacity: 1;
                img {
                    animation: goToLocation 1s infinite;
                }
            }
            span {
                color: $blackColor;
                font-size: 4vw;
                font-weight: 400 !important;
                padding-bottom: 1vw;
                border-bottom: .2vw dashed $blackColor;
                @media screen and (min-width: $mobileSize) {
                    font-size: 1vw;
                    padding-bottom: .4vw;
                    border-bottom: .07vw dashed $blackColor;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 16px !important;
                    padding-bottom: 4px;
                    border-bottom: 1px dashed $blackColor;
                }
            }   
            img {
                width: 6.9vw;
                height: 6.9vw;
                margin-right: .5vw;
                @media screen and (min-width: $mobileSize) {
                    width: 1.8vw;
                    height: 1.8vw;
                    margin-right: .5vw;
                }
                @media screen and (min-width: $largeSize) {
                    width: 26px;
                    height: 26px;
                    margin-right: 8px;
                }
            }
        }
        .footer-body__left {
            margin-bottom: 14.6vw;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media screen and (min-width: $mobileSize) {
                margin-bottom: 0;
            }
            .row {
                justify-content: space-between;
                margin-bottom: 6.6vw;
                display: flex;
                width: 100%;
                @media screen and (min-width: $mobileSize) {
                    justify-content: flex-start;
                    margin-bottom: 3.1vw;
                    flex-wrap: nowrap;
                }
                @media screen and (min-width: $largeSize) {
                    margin-bottom: 44px;
                }
            }
            img.logo {
                width: 40vw;
                @media screen and (min-width: $mobileSize) {
                    width: 10vw;
                    margin-right: 5vw;
                }
                @media screen and (min-width: $largeSize) {
                    width: 200px;
                    margin-right: 20px;
                }
            }
            h2 {
                font-size: 4.8vw;
                @media screen and (min-width: $mobileSize) {
                    font-size: 2.1vw;
                    margin-right: 1.5vw;
                    width: fit-content;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 29.5px;
                    margin-right: 22px;
                }
            }
            p.small {
                font-size: 2.4vw;
                width: 41vw;
                text-align: start;
                @media screen and (min-width: $mobileSize) {
                    font-size: 1vw;
                    width: 17vw;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 14px;
                    width: 240px;
                }
            }
            p.warning {
                text-align: start;
                font-size: 4.7vw;
                margin-bottom: 6.6vw;
                @media screen and (min-width: $mobileSize) {
                    font-size: 2.1vw;
                    margin-bottom: 3.1vw;
                    width: 39.9vw;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 30px;
                    margin-bottom: 40px;
                    width: 565px;
                }
            }
            a:not(:nth-of-type(1)) {
                font-weight: 600;
                color: black;
                text-decoration: underline;
                font-size: 4vw;
                margin-top: 4vw;
                @media screen and (min-width: $mobileSize) {
                    font-size: 1vw;
                    margin-top: 4vw;
                }
                @media screen and (min-width: $largeSize) {
                    margin-top: 10px;
                    font-size: 14px;
                }
            }
            a.outlined {
                display: flex;
                align-items: center;
                width: fit-content;
                border: .2vw solid $blackColor;
                background: transparent;
                color: $blackColor;
                padding: 3.5vw 8.8vw;
                border-radius: 2.6vw;
                font-size: 3.5vw;
                font-weight: 600;
                @media screen and (min-width: $mobileSize) {
                    font-size: 1.1vw;
                    padding: 1.1vw 2.7vw;
                    border-radius: 0.7vw;
                    border: .07vw solid $blackColor;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 16px;
                    padding: 16px 39px;
                    border-radius: 10px;
                    border: 1px solid $blackColor;
                }
                &:hover {
                    img {
                        transform: translate(.4vw, .4vw);
                        @media screen and (min-width: $mobileSize) {
                            transform: translate(.2vw, .2vw);
                        }
                        @media screen and (min-width: $largeSize) {
                            transform: translate(5px, 5px);
                        }
                    }
                }
                img {
                    transition: transform .2s ease;
                    width: 5.3vw;
                    height: 5.3vw;
                    @media screen and (min-width: $mobileSize) {
                        width: 1.7vw;
                        height: 1.7vw;
                        margin-left: .7vw;
                    }
                    @media screen and (min-width: $largeSize) {
                        width: 24px;
                        height: 24px;
                        margin-left: 10px;
                    }
                }
            }
        }
        
        .footer-body__right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media screen and (min-width: $mobileSize) {
                margin-right: 7vw;
            }
            @media screen and (min-width: $largeSize) {
                margin-right: 100px;
            }
            span {
                font-weight: 800;
                font-size: 5.8vw;
                @media screen and (min-width: $mobileSize) {
                    font-size: 1.5vw;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 22px;
                }
            }
            ul {
                margin-top: 6.6vw;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                @media screen and (min-width: $mobileSize) {
                    margin-top: 1.7vw;
                }
                @media screen and (min-width: $largeSize) {
                    margin-top: 25px;
                }
                a {
                    li {
                        font-size: 5.3vw;
                        color: $blackColor;
                        margin-bottom: 2.9vw;
                        font-weight: 500;
                        opacity: 1;
                        transition: opacity .2s ease;
                        @media screen and (min-width: $mobileSize) {
                            font-size: 1.4vw;
                            margin-bottom: .7vw;
                        }
                        @media screen and (min-width: $largeSize) {
                            font-size: 20px;
                            margin-bottom: 11px;
                        }
                        &:hover {
                            opacity: .6;
                        }
                        &.location-link {
                            margin-bottom: 0;
                            margin-top: 2.4vw;
                            display: flex;
                            align-items: center;
                            @media screen and (min-width: $largeSize) {
                                margin-top: 10px;
                            }
                            &:hover {
                                opacity: 1;
                                img {
                                    animation: goToLocation 1s infinite;
                                }
                            }
                            span {
                                font-size: 4.2vw;
                                font-weight: 400;
                                padding-bottom: 1vw;
                                border-bottom: .2vw dashed $blackColor;
                                @media screen and (min-width: $mobileSize) {
                                    font-size: 1.1vw;
                                    padding-bottom: .4vw;
                                    border-bottom: .07vw dashed $blackColor;
                                }
                                @media screen and (min-width: $largeSize) {
                                    font-size: 16px;
                                    padding-bottom: 4px;
                                    border-bottom: 1px dashed $blackColor;
                                }
                            }   
                            img {
                                width: 6.9vw;
                                height: 6.9vw;
                                margin-right: .5vw;
                                @media screen and (min-width: $mobileSize) {
                                    width: 1.8vw;
                                    height: 1.8vw;
                                    margin-right: .5vw;
                                }
                                @media screen and (min-width: $largeSize) {
                                    width: 26px;
                                    height: 26px;
                                    margin-right: 8px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

@keyframes goToLocation {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0px);
    }
}