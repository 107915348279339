@import '../../variables';

section.first {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: white;

    .section-top {
        top: 0;
        left: 0;
        width: 100%;
        height: 8.9vw;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../assets/waves-1.svg');
        transform: translateY(-.2vw);
        @media screen and (max-width: 575px) {
            background-image: url('../../assets/waves-1_mobile.svg');
            height: 26.9vw;
        }
        @media screen and (min-width: $largeSize) {
            height: 125px;
            max-width: 1400px;
        }
    }

    .section-body {
        padding: 0 7vw 0 3vw;
        height: 41.4vw;
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media screen and (min-width: $largeSize) {
            max-width: 1400px;
            padding: 0 40px;
            height: fit-content;
            margin: 0;
        }

        @media screen and (max-width: 575px) {
            flex-direction: column;
            height: fit-content;
            padding: 13.3vw 5.8vw;
        }

        .section-body__left {
            display: flex;
            align-items: center;

            @media screen and (max-width: 575px) {
                justify-content: center;
            }

            .hands {
                width: 31vw;
                transform: translateY(10vw);

                @media screen and (max-width: 575px) {
                    width: 72.5vw;
                }
                @media screen and (min-width: $largeSize) {
                    width: 435px;
                    transform: translateY(200px);
                    // height: 355px;
                }
            }
        }

        .section-body__right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 42vw;
            justify-content: center;

            @media screen and (max-width: 575px) {
                width: 100%;
                margin-top: 16vw;
            }
            @media screen and (min-width: $largeSize) {
                width: 591px;
            }

            .location-link {
                margin-bottom: 0;
                display: flex;
                align-items: center;
                &:hover {
                    opacity: 1;
                    img {
                        animation: goToLocation 1s infinite;
                    }
                }
                span {
                    color: $blackColor;
                    font-size: 4.2vw;
                    font-weight: 400;
                    padding-bottom: 1vw;
                    border-bottom: .2vw dashed $blackColor;
                    @media screen and (min-width: $mobileSize) {
                        font-size: 1.1vw;
                        padding-bottom: .4vw;
                        border-bottom: .07vw dashed $blackColor;
                    }
                    @media screen and (min-width: $largeSize) {
                        font-size: 16px;
                        padding-bottom: 4px;
                        border-bottom: 1px dashed $blackColor;
                    }
                }   
                img {
                    width: 6.9vw;
                    height: 6.9vw;
                    margin-right: .5vw;
                    @media screen and (min-width: $mobileSize) {
                        width: 1.8vw;
                        height: 1.8vw;
                        margin-right: .5vw;
                    }
                    @media screen and (min-width: $largeSize) {
                        width: 26px;
                        height: 26px;
                        margin-right: 8px;
                    }
                }
            }
            i {
                font-style: italic;
                text-align: start;
                font-size: 0.7vw;
                margin-top: 2.1vw;
                @media screen and (min-width: $largeSize) {
                    font-size: 10px;
                    margin-top: 30px;
                }
                @media screen and (max-width: 575px) {
                    font-size: 2.6vw;
                    margin-top: 7.8vw;
                }
            }
            h2 {
                text-align: start;
                font-weight: 800;
                font-size: 1.5vw;
                margin: 1.1vw 0;

                @media screen and (max-width: 575px) {
                    font-size: 5.3vw;
                    margin: 5.3vw 0;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 22px;
                    margin: 16px 0;
                }
            }

            p {
                text-align: start;
                font-size: 1.1vw;

                @media screen and (max-width: 575px) {
                    font-size: 3.4vw;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 16px;
                }
            }

            ul {
                margin-left: 2vw;
                margin-top: 1.07vw;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                @media screen and (max-width: 575px) {
                    margin-left: 4vw;
                    margin-top: 5.3vw;
                }
                @media screen and (min-width: $largeSize) {
                    margin-left: 30px;
                }

                li {
                    text-align: start;
                    font-size: 1.1vw;
                    list-style: disc;

                    @media screen and (max-width: 575px) {
                        font-size: 3.4vw;
                    }
                    @media screen and (min-width: $largeSize) {
                        font-size: 16px;
                    }
                }
            }

            .row {
                margin-top: 1.78vw;

                a:first-child {
                    margin-right: 1.78vw;

                    @media screen and (max-width: 575px) {
                        margin-right: 4vw;
                    }
                    @media screen and (min-width: $largeSize) {
                        margin-right: 25px;
                    }
                }

                @media screen and (max-width: 575px) {
                    margin-top: 5.8vw;
                    width: 100%;
                }
                @media screen and (min-width: $largeSize) {
                    margin-top: 25px;
                }

                .outlined {
                    background: white;
                    border: 0.07vw solid $yellowColorLinks;
                    @media screen and (min-width: $largeSize) {
                        border: 1px solid $yellowColorLinks;
                    }
                }

                .filled {
                    background: $yellowColorLinks;
                    color: $blackColor;
                    @media screen and (max-width: 575px) {
                        max-width: 40%;

                    }
                }                
                
                .filled,
                .outlined {
                    font-size: 1.1vw;
                    padding: 1vw 2.7vw;
                    border-radius: 0.7vw;
                    font-weight: 600;
                    white-space: nowrap;
                    color: $blackColor;
                
                    @media screen and (max-width: 575px) {
                        font-size: 2.9vw;
                        padding: 3vw;
                        width: 100%;
                        border-radius: 1.6vw;
                    }
                    @media screen and (min-width: $largeSize) {
                        font-size: 16px;
                        padding: 14px 39px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}
.row {
    display: flex;
    align-items: center;
}