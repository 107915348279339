@import '../../variables';

.slider {
    background-color: $yellowColor;
    width: 100%;
    height: 44.2vw;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 575px) {
        height: calc(100vh - 21.3vw);   
    }
    @media screen and (min-width: $largeSize) {
        max-width: 1400px;
        max-height: 620px;
    }
}

.slider-button {
    position: absolute;
    background: transparent;
    cursor: pointer;
    transform: translateY(-50%);
    top: 50%;
    z-index: 5;
    @media screen and (max-width: 575px) {
        display: none;
    }
}

.slider-button__prev {
    left: 2.8vw;
    @media screen and (min-width: $largeSize) {
        left: 40px;
    }
}

.slider-button__next { 
    right: 2.8vw;
    @media screen and (min-width: $largeSize) {
        right: 40px;
    }
}

.slider-current-position__wrapper {
    width: fit-content;
    height: fit-content;
    display: flex;
    bottom: 40px;
    right: 50%;
    z-index: 5;
    transform: translateX(50%);
    position: absolute;
    @media screen and (max-width: 575px) {
        bottom: 3vw;
    }
    .slider-current-position__element-wrapper {
        border-radius: .3vw;
        margin-right: 0.7vw;
        height: 0.7vw;
        width: 3.5vw;
        background: rgba($color: #fff, $alpha: .4);
        overflow: hidden;
        @media screen and (max-width: 575px) {
            height: 1.3vw;
            width: 5.3vw;
            margin-right: 2vw;
        }
        @media screen and (min-width: $largeSize) {
            border-radius: 5px;
            margin-right: 11px;
            height: 10px;
            width: 50px;
        }
        &:last-child {
            margin-right: 0;
        }
        .slider-current-position__element {
            background: white;
            width: 0%;
            height: 100%;
            border-radius: 5px;
            &.active {
                animation: sliderPositionAnimation 5s linear;
            }
        }
    }
}

.slider-body {
    // background: red;
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    transition: 1s ease transform;
    .slider-body__current-view {
        width: 100vw;
        max-width: 100vw;
        @media screen and (min-width: $largeSize) {
            width: 1400px;
        }
    }
}

.slider-body__current-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slider-body__current-view-left {
        width: 50%;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        padding-right: 2vw;
        @media screen and (min-width: $largeSize) {
            padding-right: 30px;
        }
    }
    .slider-body__current-view-right {
        width: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        padding-left: 2vw;
        @media screen and (min-width: $largeSize) {
            padding-left: 30px;
        }
    }
    &:nth-of-type(1), &:nth-of-type(3) {
        @media screen and (max-width: 575px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        a {
            color: black;
            text-decoration: underline;
        }
        .slider-body__current-view-left {
            @media screen and (max-width: 575px) {
                padding-right: 0;
                width: fit-content;
            }
            img {
                width: 38vw;
                
                @media screen and (min-width: $largeSize) {
                    width: 530px;
                }
                @media screen and (max-width: 575px) {
                    width: 70vw;
                }
            }
        }
        .slider-body__current-view-right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media screen and (max-width: 575px) {
                width: 90%;
                margin-top: 2vw;
                padding-left: 0;
            }
            h2 {
                font-size: 2vw;
                margin-bottom: 1vw;
                @media screen and (min-width: $largeSize) {
                    margin-bottom: 15px;
                    font-size: 30px;
                }
                @media screen and (max-width: 575px) {
                    margin-bottom: 3vw;
                    font-size: 6vw;
                }
            }
            p {
                font-size: 1.2vw;
                text-align: start;
                max-width: 65%;
                @media screen and (max-width: 575px) {
                    font-size: 4vw;
                    max-width: unset;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 16px;
                }
            }
            ul {
                margin-top: 1.5vw;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                @media screen and (min-width: $largeSize) {
                    margin-top: 22.5px;
                }
                @media screen and (max-width: 575px) {
                    margin-top: 4vw;
                }
                li {
                    margin-left: 2vw;
                    list-style: disc;
                    max-width: 80%;
                    font-size: 1vw;
                    text-align: start;
                    @media screen and (min-width: $largeSize) {
                        margin-left: 30px;
                        font-size: 16px;
                    }
                    @media screen and (max-width: 575px) {
                        margin-left: 5vw;
                        font-size: 4vw;
                        max-width: unset;
                    }
                }
            }
            .row {
                margin-top: 2vw;
                @media screen and (min-width: $largeSize) {
                    margin-top: 30px;
                }
                @media screen and (max-width: 575px) {
                    margin-top: 4vw;
                    width: 100%;
                }
                button, a {
                    display: flex;
                    padding: 1vw 2vw;
                    font-size: 1vw;
                    border-radius: .4vw;
                    font-weight: 600;
                    transition: opacity .2s ease;
                    background: white;
                    color: $blackColor;
                    cursor: pointer;
                    @media screen and (min-width: $largeSize) {
                        padding: 15px 30px;
                        font-size: 15px;
                        border-radius: 5px;
                        line-height: 15px;
                    }
                    @media screen and (max-width: 575px) {
                        padding: 4vw 0;
                        width: 50%;
                        display: flex;
                        white-space: nowrap;
                        font-size: 3vw;
                        border-radius: 1.33vw;
                        align-items: center;
                        justify-content: center;
                        &:nth-of-type(1) {
                            padding: 3vw 5vw;
                            width: fit-content;
                        }
                    }
                    &.ml-1 {
                        margin-left: 2vw;
                        @media screen and (min-width: $largeSize) {
                            margin-left: 30px;
                        }
                        @media screen and (max-width: 575px) {
                            margin-left: 2vw;
                            width: 100%;
                        }
                    }
                    &:hover {
                        opacity: .7;
                    }
                }
            }
        }

    }
    &:nth-of-type(2), &:nth-of-type(4) {
        background-image: url('../../assets/slider-2.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media screen and (max-width: 575px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .slider-body__current-view-left {
            justify-content: center;
            @media screen and (max-width: 575px) {
                width: 100%;
            }
        }
        .box {
            background: rgba(255,221,0,.7);
            width: 26vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 1vw;
            @media screen and (min-width: $largeSize) {
                width: 364px;
            }
            @media screen and (max-width: 575px) {
                width: 90%;
                padding: 3vw;
            }
            h3 {
                text-align: start;
                font-size: 1.5vw;
                color: #52646d;
                margin-bottom: 1vw;
                @media screen and (min-width: $largeSize) {
                    font-size: 22px;
                    margin-bottom: 15px;
                }
                @media screen and (max-width: 575px) {
                    font-size: 5vw;
                    margin-bottom: 4vw;
                }
            }
            p {
                font-size: 1.2vw;
                color: #52646d;
                font-weight: 600;
                text-align: start;
                margin-bottom: 1vw;
                @media screen and (min-width: $largeSize) {
                    font-size: 18px;
                    margin-bottom: 15px;
                }
                @media screen and (max-width: 575px) {
                    font-size: 4vw;
                    margin-bottom: 4vw;
                }
            }
            button {
                display: flex;
                width: fit-content;
                text-transform: uppercase;
                border: .1vw solid #52646d;
                padding: .5vw;
                line-height: 1.2vw;
                font-size: 1vw;
                font-weight: 800;
                background: transparent;
                color: #52646d;
                align-items: center;
                cursor: pointer;
                transition: background-color .2s ease;
                @media screen and (min-width: $largeSize) {
                    font-size: 16px;
                    line-height: 16px;
                    padding: 10px;
                    border: 1px solid #52646d;
                }
                @media screen and (max-width: 575px) {
                    font-size: 4vw;
                    line-height: 4vw;
                    padding: 3vw;
                    border: .25vw solid #52646d;
                }
                &:hover {
                    background-color: rgba($color: #fff, $alpha: .3);
                }
                &::after {
                    margin-left: .5vw;
                    content: "";
                    font-family: "Open Sans",Helvetica,Arial,sans-serif!important;
                    display: inline-block;
                    position: relative;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: .43vw 0 .43vw .7vw;
                    border-color: transparent transparent transparent #52646d;
                    @media screen and (max-width: 575px) {
                        border-width: 2vw 0 2vw 3vw;
                        margin-left: 2vw;
                    }
                }
            }
        }
    }
    &:nth-of-type(4) {
        background-image: url('../../assets/slider-4.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

@keyframes sliderPositionAnimation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
