@import '../../variables';

.page-text {
    ul {
        li {
            list-style: disc;
        }
        ul {
            margin-left: 4vw;
            @media screen and (min-width: $mobileSize) {
                margin-left: 2vw;
            }
            @media screen and (min-width: $largeSize) {
                margin-left: 20px;
            }

        }
    }
}