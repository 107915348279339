// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import './variables';

body {
  margin: 0;
  font-family: 'Open Sans', 'sans-serif';
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  border: none;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5.7vw;
  min-height: 50vh;

  @media screen and (max-width: 575px) {
    margin-top: 21vw;

    img {
      max-width: 100% !important;
      width: 90%;
      float: unset !important;
      // margin: 0 auto !important;
      // margin: 0 0 8vw 0 !important;
    }
  }

  @media screen and (min-width: $largeSize) {
    margin-top: 80px;
  }
}

.page-text {
  width: 100%;
  max-width: 1400px;

  margin-top: 10vw;
  @media screen and (min-width: $mobileSize) {
    margin-top: 5vw;
  }

  @media screen and (min-width: $largeSize) {
    margin-top: 60px;
  }
  

  img {
    margin: 10vw 0;
    width: 100%;
    &.mb {
      margin: 0 0 10vw 0;
    }
    &.mt {
      margin: 10vw 0 0 0;
    }
    &.mb-ml {
      margin: 0 0 10vw 0vw;
    }
    @media screen and (min-width: $mobileSize) {
      margin: 4vw 0;
        width: 60%;
      &.mb {
        margin: 0 0 4vw 0;
      }
      &.mt {
        margin: 4vw 0 0 0;
      }
      &.mb-ml {
        margin: 0 0 4vw 4vw;
      }
    }

    @media screen and (min-width: $largeSize) {
      margin: 40px 0;
      &.mb {
        margin: 0 0 40px 0;
      }
      &.mt {
        margin: 40px 0 0 0;
      }
      &.mb-ml {
        margin: 0 0 40px 40px;
      }
    }
  }

 

  article {
    // display: flex;
    // flex-direction: column;
    text-align: center;
    padding: 0 5.3vw;

    @media screen and (min-width: $mobileSize) {
      padding: 0 9.4vw;
    }
    @media screen and (min-width: $largeSize) {
      padding: 0 132px;
    }
  }

  a {
    color: black;
    text-decoration: underline;
    font-size: 4.2vw;
    line-height: 6.66vw;
    @media screen and (min-width: $mobileSize) {
      font-size: 1.1vw;
      line-height: 1.8vw;
    }
    @media screen and (min-width: $largeSize) {
      font-size: 16px;
      line-height: 25px;
    }
  }

  * {
    word-break: break-word;
    text-align: start;
    font-size: 4.2vw;
    line-height: 6.6vw;
    @media screen and (min-width: $mobileSize) {
      font-size: 1.1vw;
      line-height: 1.8vw;
    }
    
    @media screen and (min-width: $largeSize) {
      font-size: 16px;
      line-height: 25px;
    }
  }

  strong {
    font-size: 4.2vw;
    line-height: 6.66vw;
    margin-bottom: 2.1vw;
    font-weight: 700;
    text-align: justify;

    @media screen and (min-width: $mobileSize) {
      font-size: 1.1vw;
      line-height: 1.8vw;
    }
    @media screen and (min-width: $largeSize) {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 30px;
    }
  }

  small, small > a {
    margin-top: .3vw;
    font-size: 3.5vw;
    line-height: 5.5vw;
    @media screen and (min-width: $mobileSize) {
      font-size: .9vw;
      line-height: 1vw;
    }
    @media screen and (min-width: $largeSize) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  p {
    font-size: 4.2vw;
    line-height: 6.66vw;
    margin-bottom: 2.1vw;
    font-weight: 500;
    text-align: justify;

    @media screen and (min-width: $mobileSize) {
      font-size: 1.1vw;
      line-height: 1.8vw;
    }
    @media screen and (min-width: $largeSize) {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 30px;
    }

    strong {
      margin-bottom: 0;
      display: inline;
    }
  }

  ul,
  strong {
    margin-bottom: 8vw;

    @media screen and (min-width: $mobileSize) {
      margin-bottom: 2.1vw;
    }
    @media screen and (min-width: $largeSize) {
      margin-bottom: 30px;
    }
  }

  strong {
    display: block;
  }
}

.yellow-line {
  background: $yellowColor;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  height: 32vw;


  @media screen and (min-width: $mobileSize) {
    height: 8.5vw;
  }
  @media screen and (min-width: $largeSize) {
    height: 120px;
  }

  h3 {
    text-align: start;
    font-weight: 900;
    margin-left: 5.3vw;
    font-size: 7.5vw;

    @media screen and (min-width: $mobileSize) {
      margin-left: 9.4vw;
      font-size: 2vw;
    }
    @media screen and (min-width: $largeSize) {
      font-size: 28px;
      margin-left: 132px;
    }

  }
}

.common-button {
  white-space: nowrap;
  font-weight: 600;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.5vw;
  border-radius: 0.7vw;
  height: 2.7vw;
  font-size: 1.1vw;
  color: $blackColor;

  @media screen and (min-width: $largeSize) {
    height: 39px;
    width: 175px;
    font-size: 16px;
    border-radius: 10px;
  }
}

.section-title {
  display: flex;
  width: 100%;

  h4 {
    font-size: 5.3vw;

    @media screen and (min-width: $mobileSize) {
      font-size: 2vw;
    }

    @media screen and (min-width: 1400px) {
      font-size: 28px;
    }
  }
}

sup {
    font-size: 2.2vw;
    line-height: 2.6vw;
    @media screen and (min-width: $mobileSize) {
      font-size: .7vw;
      line-height: .8vw;
    }
    
    @media screen and (min-width: $largeSize) {
      font-size: 12px !important;
      line-height: 15px !important;
    }

}

.big-link {
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: .1);
  display: flex;
  width: 39.8vw;
  height: 17.1vw;
  justify-content: center;
  overflow: hidden;
  border-radius: 2.8vw;
  transition: box-shadow .2s ease;
  margin-bottom: 10vw;
  @media screen and (min-width: $largeSize) {
      border-radius: 40px;
      width: 558px;
      height: 240px;
      margin-bottom: 200px;
  }
  @media screen and (max-width: 575px) {
      width: 100%;
      height: 37.3vw;
      border-radius: 6.6vw;
      &:last-child {
          margin-top: 8vw;
      }
  }
  &:hover {
      box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: .2);
      img {
          transform: scale(1.1);
      }
  } 
  img {
      transition: transform .2s ease;
      width: 100%;
      object-fit: cover;
  }
}

.fifth {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 0;
  width: 88.2vw;
  @media screen and (min-width: $largeSize) {
      max-width: 1400px;
      padding: 0 40px;
  }

  @media screen and (min-width: $mobileSize) {
    padding: 0 3vw 0 3vw;
  }
}