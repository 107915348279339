@import '../../variables';


.header-wrapper {
    background: white;
    height: 5.7vw;
    display: flex;
    align-items: center;
    padding: 0 40px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    width: 100%;
    transition: background .5s ease, color .5s ease;
    @media screen and (max-width: 575px) {
        padding: 0 5.8vw;
        height: 21.3vw;
    }
    @media screen and (min-width: $largeSize) {
        height: 80px;
    }
    &.isOpen {
        .header-left {
            .burger-button {
                transform: translateX(21.4vw);
                @media screen and (min-width: $largeSize) {
                    transform: translateX(300px);
                }
                @media screen and (max-width: 575px) {
                    transform: unset;
                }
                span {
                    background: white;
                }
                z-index: 20;
            }
            p {
                z-index: 20;
                transform: translateX(-1.1vw);
                color: white;
                @media screen and (min-width: $largeSize) {
                    transform: translateX(-16px);
                }
            }
        }
        .burger-button {
            span {
                &:first-child {
                    transform: translateY(.6vw) rotate(45deg);
                }
                &:last-child {
                    transform: translateY(-.6vw) rotate(-45deg);
                    width: 100%;
                }
                &:nth-of-type(2) {
                    opacity: 0;
                }
            }
        } 
        @media screen and (max-width: 575px) {
            background: rgba($orangeColor, .95);
            color: white;
            backdrop-filter: blur(4px);
            .burger-button {
                span {
                    background: white;
                    &:first-child {
                        transform: translateY(2.1vw) rotate(45deg);
                        // transform: translateY(50%);
                    }
                    &:last-child {
                        transform: translateY(-2.1vw) rotate(-45deg);
                        width: 100%;
                    }
                    &:nth-of-type(2) {
                        // transform: translateX(2vw);
                        opacity: 0;
                    }
                }
            }     
        }
        @media screen and (min-width: $largeSize) {
            .burger-button {
                span {
                    &:first-child {
                        transform: translateY(7.5px) rotate(45deg);
                    }
                    &:last-child {
                        transform: translateY(-7.5px) rotate(-45deg);
                        width: 100%;
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                }
            }     
        }    
    }
    header {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        @media screen and (max-width: 575px) {
            flex-direction: row-reverse;
        }
        @media screen and (min-width: $largeSize) {
            max-width: 1400px;
            // padding: 0 40px;
        }
        .header-left {
            display: flex;
            align-items: center;
            p {
                margin-left: 1vw;
                font-weight: 700;
                @media screen and (min-width: $mobileSize) {
                    font-size: 1.4vw;
                }
                @media screen and (min-width: $largeSize) {
                    margin-left: 15px;
                    font-size: 20px;
                }
            }
        }
        .header-right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 51vw;
            @media screen and (min-width: $largeSize) {
                width: 56.5%;
            }
            h1 {
                font-size: 1.4vw;
                
                @media screen and (max-width: 575px) {
                    font-size: 5vw;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 20px;
                }
            }
            .header-right__buttons {
                align-items: center;
                display: flex;
                @media screen and (max-width: 575px) {
                    display: none;
                }
                button {
                    background-color: transparent;
                    cursor: pointer;
                }
                .location-link {
                    // margin-right: 3.6vw;
                    display: flex;
                    align-items: center;
                    @media screen and (min-width: $largeSize) {
                        // margin-right: 51px;
                    }
                    &:hover {
                        opacity: 1;
                        img {
                            animation: goToLocation 1s infinite;
                        }
                    }
                    span {
                        color: $blackColor;
                        font-size: 4.2vw;
                        font-weight: 400;
                        padding-bottom: 1vw;
                        border-bottom: .2vw dashed $blackColor;
                        @media screen and (min-width: $mobileSize) {
                            font-size: 1.1vw;
                            padding-bottom: .4vw;
                            border-bottom: .07vw dashed $blackColor;
                        }
                        @media screen and (min-width: $largeSize) {
                            font-size: 16px;
                            padding-bottom: 4px;
                            border-bottom: 1px dashed $blackColor;
                        }
                    }   
                    img {
                        width: 6.9vw;
                        height: 6.9vw;
                        margin-right: .5vw;
                        @media screen and (min-width: $mobileSize) {
                            width: 1.8vw;
                            height: 1.8vw;
                            margin-right: .5vw;
                        }
                        @media screen and (min-width: $largeSize) {
                            width: 26px;
                            height: 26px;
                            margin-right: 8px;
                        }
                    }
                }
                .common-button {
                    background-color: $redColor;
                    color: white;
                    margin-right: 6.4vw;
                    @media screen and (min-width: $largeSize) {
                        margin-right: 90px;
                    }
                }
            }
        }
    }
}

.burger-button {
    width: 2.8vw;
    height: 2.8vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.7vw .3vw;
    background: transparent;
    cursor: pointer;
    @media screen and (max-width: 575px) {
        align-items: flex-end;
        width: 9vw;
        height: 9vw;
        padding: 2.2vw 1vw;
    }    
    @media screen and (min-width: $largeSize) {
        width: 36px;
        height: 36px;
        padding: 9px 4.5px;
    }
    span {
        width: 100%;
        height: .2vw;
        background-color: $blackColor;
        border-radius: .14vw;
        opacity: 1;
        transition: transform .25s ease, background .5s ease, opacity .1s ease;
        @media screen and (max-width: 575px) {
            border-radius: .25vw;
            height: .5vw;
        }
        @media screen and (min-width: $largeSize) {
            height: 3px;
            border-radius: 2px;
        }
        &:nth-child(2) {
            width: 60%;
        }
        &:nth-child(3) {
            width: 80%;
        }
    }
}