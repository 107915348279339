@import '../../variables';

section.fourth {
    margin-top: 6.8vw;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 1136px;
    width: 81.1vw;
    @media screen and (min-width: 1400px) {
        margin-top: 96px;
        margin-bottom: 186px;
    }
    @media screen and (max-width: 575px) {
        width: 88.2vw;
    }
    .section-body {
        width: 100%;
        .big-links-row {
            width: 100%;
            margin: 5.7vw 0;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            @media screen and (min-width: 1400px) {
                margin-top: 80px;
                margin-bottom: 0;
            }
            @media screen and (max-width: 575px) {
                flex-direction: column;
                margin: 8vw 0 25.3vw 0;
            }
            a {
                box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: .1);
                display: flex;
                width: 39.8vw;
                height: 17.1vw;
                justify-content: center;
                overflow: hidden;
                border-radius: 2.8vw;
                transition: box-shadow .2s ease;
                transform: translateZ(0px);
                @media screen and (min-width: $largeSize) {
                    border-radius: 40px;
                    width: 558px;
                    height: 240px;
                }
                @media screen and (max-width: 575px) {
                    width: 100%;
                    height: 37.3vw;
                    border-radius: 6.6vw;
                    &:last-child {
                        margin-top: 8vw;
                    }
                }
                &:hover {
                    box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: .2);
                    img {
                        transform: scale(1.1);
                    }
                } 
                img {
                    transition: transform .2s ease;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}