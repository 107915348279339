@import '../../variables';

.cookie-wrapper {
    position: fixed;
    z-index: 100;
    width: 90%;
    max-width: 1000px;
    top: 50%;
    color: black;
    background: white;
    right: 50%;
    transform: translateX(50%) translateY(-50%);
    height: fit-content;
    display: flex;
    text-align: start;
    box-shadow: 1vw 1vw 6vw rgba(0, 0, 0, 0.15);
    border-radius: 2.5vw;

    @media screen and (min-width: $mobileSize) {
        box-shadow: 1vw 1vw 6vw rgba(0, 0, 0, 0.15);
        border-radius: 1vw;
    }

    @media screen and (min-width: $largeSize) {
        box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        width: 100%;
    }

    .cookie-inner {
        padding: 7.5vw 5vw;

        @media screen and (min-width: $mobileSize) {
            padding: 2.5vw;
        }

        @media screen and (min-width: $largeSize) {
            padding: 30px 46px;
        }

        p, a {
            font-size: 4vw;
            @media screen and (min-width: $mobileSize) {
                font-size: 2vw;
            }
            @media screen and (min-width: $largeSize) {
                font-size: 16px;
            }

        }
        a {
            color: black;
            text-decoration: underline;
        }
        .row {
            display: flex;
            justify-content: flex-end;
            padding-top: 10vw;

            @media screen and (min-width: $mobileSize) {
                padding-top: 2vw;
            }

            @media screen and (min-width: $largeSize) {
                padding-top: 30px;
            }
        }

        button.filled {
            background-color: $yellowColor;
            cursor: pointer;
            font-weight: 600;
            transition: opacity .2s ease;
            border-radius: 1vw;
            padding: 2vw 4vw;

            font-size: 3vw;
            @media screen and (min-width: $mobileSize) {
                font-size: 2vw;
                padding: 1.5vw 2vw;
                border-radius: .4vw;
            }

            @media screen and (min-width: $largeSize) {
                font-size: 16px;
                border-radius: 5px;
                padding: 12px 20px;
            }

            &:hover {
                opacity: .8;
            }

            &:nth-of-type(1) {
                margin-right: 4vw;

                @media screen and (min-width: $mobileSize) {
                    margin-right: 2vw;
                }

                @media screen and (min-width: $largeSize) {
                    margin-right: 20px;
                }
            }
        }
    }
}