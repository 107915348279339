@import '../../variables';

menu.burger-wrapper {
    width: 100%;
    position: fixed;
    z-index: 10;
    background: white;
    transform: translateY(-100vh);
    transition:  transform .5s ease, background .5s ease, opacity .5s ease;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    opacity: 0;
    backdrop-filter: blur(4px);
    @media screen and (min-width: $largeSize) {
        margin-left: 13.15vw;
        opacity: 1;
    }
    @media screen and (max-width: 575px) {
        height: calc(100vh - 21.3vw);
        overflow: auto;
    }
    &.desktop {
        width: 355px;
        height: fit-content;
        position: fixed;
        z-index: 100;
        .burger-inner {
            * {
                font-size: 1vw;
                margin: 0;
                padding: 0;
                width: 0;
                height: 0;
            }
            .test-button {
                display: none;
            }
            .location-link {
                margin-right: 3.6vw;
                display: flex;
                align-items: center;
                @media screen and (min-width: $largeSize) {
                    margin-right: 51px;
                }
                &:hover {
                    opacity: 1;
                    img {
                        animation: goToLocation 1s infinite;
                    }
                }
                span {
                    color: $blackColor;
                    font-size: 4.2vw;
                    font-weight: 400;
                    padding-bottom: 1vw;
                    border-bottom: .2vw dashed $blackColor;
                    @media screen and (min-width: $mobileSize) {
                        font-size: 1.1vw;
                        padding-bottom: .4vw;
                        border-bottom: .07vw dashed $blackColor;
                    }
                    @media screen and (min-width: $largeSize) {
                        font-size: 16px;
                        padding-bottom: 4px;
                        border-bottom: 1px dashed $blackColor;
                    }
                }   
                img {
                    width: 6.9vw;
                    height: 6.9vw;
                    margin-right: .5vw;
                    @media screen and (min-width: $mobileSize) {
                        width: 1.8vw;
                        height: 1.8vw;
                        margin-right: .5vw;
                    }
                    @media screen and (min-width: $largeSize) {
                        width: 26px;
                        height: 26px;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
    &.isOpen {
        background: rgba($orangeColor, .95);
        transform: translateY(0vh);
        opacity: 1;
        @media screen and (min-width: $largeSize) {
            transform: translateY(-80px);
            background: rgba($orangeColor, 1);
        }
    }
    .burger-inner {
        margin: 18vw 0;
        width: 100%;
        padding: 0 5.8vw;
        ul {
            a, button {
                display: flex;
                margin-bottom: 5.3vw;
                flex-direction: column;
                align-items: flex-start;
                width: fit-content;
                cursor: pointer;
                background-color: transparent;
                .hover-block {
                    width: 0%;
                    height: .3vw;
                    background: white;
                    transition: width .2s ease;
                    margin-top: 1.3vw;
                }
                &:hover {
                    .hover-block {
                        width: 100%;
                    }
                }
                li {
                    text-align: start;
                    font-size: 6.4vw;
                    font-weight: 500;
                    color: white;
                    
                    &.location-link {
                        margin-bottom: 0;
                        display: flex;
                        align-items: center;
                        margin-bottom: 10.6vw;
                        span {
                            font-size: 4.2vw;
                            font-weight: 400;
                            padding-bottom: 1vw;
                            border-bottom: .5vw dashed white;
                        }   
                        img {
                            width: 6.9vw;
                            height: 6.9vw;
                            margin-right: .5vw;
                        }
                    }
                    &.test-button {
                        width: 69.3vw;
                        height: 16vw;
                        font-size: 5.8vw;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $redColor;
                        border-radius: 2.6vw;
                    }
                }
            }
        }
    }
}