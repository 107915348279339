@import '../../variables';

.dropdown {
    background: $orangeColor;
    height: fit-content;
    position: absolute;
    width: 25.3vw;
    top: 0;
    left: 0;
    padding: 0px 2vw 0px 2.5vw;
    box-shadow: 0.28vw 0.28vw 1.7vw rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 0.7vw 0px;
    transform: translateY(-100%);
    transition: transform .5s ease;
    @media screen and (max-width: 575px) {
        display: none;
    }
    @media screen and (min-width: $largeSize) {
        width: 355px;
        padding: 0px 29px 0px 36px;
        box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 10px 0px;
    }
    &.isOpen {
        transform: translateY(0);
    }
    .dropdown-inner {
        .dropdown-header {
            height: 5.7vw;
            border-bottom: 0.07vw solid rgba(255, 255, 255, 0.3);
            @media screen and (min-width: $largeSize) {
                height: 80px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            }
        }
        .dropdown-body {
            height: fit-content;
            padding: 2.1vw 0;
            @media screen and (min-width: $largeSize) {
                padding: 30px 0;
            }
            ul {
                .location-link {
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    span {
                        color: white;
                        font-size: 1.1vw;
                        font-weight: 400;
                        padding-bottom: 0.28vw;
                        border-bottom: 0.07vw dashed white;
                        @media screen and (min-width: $largeSize) {
                            padding-bottom: 4px;
                            border-bottom: 1px dashed white;
                            font-size: 16px;
                        }
                    }   
                    img {
                        width: 1.8vw;
                        height: 1.8vw;
                        margin-right: .5vw;
                        @media screen and (min-width: $largeSize) {
                            width: 26px;
                            height: 26px;
                            margin-right: 7px;
                        }
                    }
                }
                button {
                    background-color: transparent;
                    cursor: pointer;
                    color: white;
                }
                .location-link {
                    // margin-right: 3.6vw;
                    display: flex;
                    align-items: center;
                    @media screen and (min-width: $largeSize) {
                        // margin-right: 51px;
                    }
                    &:hover {
                        opacity: 1;
                        img {
                            animation: goToLocation 1s infinite;
                        }
                    }
                    span {
                        color: white;
                        font-size: 4.2vw;
                        font-weight: 400;
                        padding-bottom: 1vw;
                        border-bottom: .2vw dashed white;
                        @media screen and (min-width: $mobileSize) {
                            font-size: 1.1vw;
                            padding-bottom: .4vw;
                            border-bottom: .07vw dashed white;
                        }
                        @media screen and (min-width: $largeSize) {
                            font-size: 16px;
                            padding-bottom: 4px;
                            border-bottom: 1px dashed white;
                        }
                    }   
                    img {
                        width: 6.9vw;
                        height: 6.9vw;
                        margin-right: .5vw;
                        @media screen and (min-width: $mobileSize) {
                            width: 1.8vw;
                            height: 1.8vw;
                            margin-right: .5vw;
                        }
                        @media screen and (min-width: $largeSize) {
                            width: 26px;
                            height: 26px;
                            margin-right: 8px;
                        }
                    }
                }
 
                a, button {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-bottom: 1.4vw;
                    width: fit-content;
                    @media screen and (min-width: $largeSize) {
                        margin-bottom: 20px;
                    }
                    .hover-block {
                        width: 0%;
                        height: 0.07vw;
                        background: white;
                        transition: width .2s ease;
                        margin-top: 0.28vw;
                        @media screen and (min-width: $largeSize) {
                            margin-top: 4px;
                            height: 1px;
                        }
                    }
                    &:hover {
                        .hover-block {
                            width: 100%;
                        }
                    }
                    li {
                        text-align: start;
                        font-size: 1.4vw;
                        font-weight: 500;
                        color: white;
                        
                        @media screen and (min-width: $largeSize) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}