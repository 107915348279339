@import '../../variables';

.yellow-line {
    h3.custom {
        @media screen and (max-width: 575px) {
            font-size: 7vw;
        }
    }
}


.show-on-mobile {
    display: none !important;
}

table {
    border-spacing: 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    margin-bottom: 30px;
    @media screen and (max-width: 575px) {
        margin-bottom: 10vw;
    }
    td {
        width: 33%;
        padding: 10px;
    }
    thead {
        @media screen and (max-width: 575px) {
            display: none;
        }
        background-color: #F8F8F8;
        td {
            font-weight: 600;
            border-bottom: 1px solid #d7d7d7;
            &:not(:nth-last-of-type(1)) {
                border-right: 1px solid #d7d7d7;
            }
        }
    }
    tbody {
        @media screen and (max-width: 575px) {
            display: flex;
            width: 100%;
            flex-direction: column;
        }
        tr {
            @media screen and (max-width: 575px) {
                width: 100%;
                max-width: 100%;
                display: flex;
                flex-direction: column;
                &:nth-last-of-type(1) {
                    td:nth-last-of-type(1) {
                            border-bottom: none !important;
                    }
                }
            }
            td {
                &:not(:nth-last-of-type(1)) {
                    border-right: 1px solid #d7d7d7;
                }
                
                @media screen and (max-width: 575px) {
                    width: 100%;
                    border-right: none !important;
                    border-bottom: .26vw solid #d7d7d7;
                    .show-on-mobile {
                        display: flex !important;
                        margin: 0;
                    }
                }
            }
            &:not(:nth-last-of-type(1)) {
                td {
                    border-bottom: 1px solid #d7d7d7;
                }
            }

        }
    }
}