@import '../../variables';

section.third {
  position: relative;
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 0 5.8vw;
  }
  .section-title {
    padding-left: 9.4vw;
    margin-top: 6vw;
    max-width: 1136px;
    width: 81.1vw;
    @media screen and (max-width: 575px) {
      padding-left: 0;
    }
    @media screen and (min-width: $largeSize) {
      margin: 90px auto 0 auto;
      padding: 0;
      width: 1136px;
    }
  }
  .section-body {
    position: relative;

    @media screen and (max-width: 575px) {
      margin-top: 35vw;
    }

    @media screen and (min-width: $largeSize) {
      width: 1400px;
      margin: 180px auto;
    }
  }
  .bottle-wrapper {
    width: 35.7vw;
    height: 35.7vw;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (max-width: 575px) {
      top: 0;
      left: 0;
      width: 88.2vw;
      height: 50vw;
      transform: translateY(-50%);
    }

    @media screen and (min-width: $largeSize) {
      width: 466px;
      height: 466px;
    }
    .light {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 70vw;
      height: 70vw;
      user-select: none;
      pointer-events: none;
      @media screen and (max-width: 575px) {
        width: 150vw;
        height: 150vw;
      }
      @media screen and (min-width: $largeSize) {
        width: 900px;
        height: 900px;
      }
    }
    .bottle {
      position: absolute;
      pointer-events: none;
      user-select: none;
      width: 34vw;
      top: 50%;
      transform: translateY(-50%);
      @media screen and (max-width: 575px) {
        width: 88.2vw;
        height: 51.7vw;
      }
      @media screen and (min-width: $largeSize) {
        width: 466px;
        height: 281px;
      }
    }
  }

  .circles-wrapper {
    background-repeat: no-repeat;
    background-size: contain;
    height: 50vw;
    background-position: center;
    margin: 10vw 0;
    background-image: url('../../assets/line-1.svg');

    @media screen and (max-width: 575px) {
      background-image: url('../../assets/line-2.svg');
      height: 150vw;
      margin: 20vw 0 40vw 0;
    }

    @media screen and (min-width: $largeSize) {
      margin: 140px 0;
      height: 700px;
    }
    .circle {
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: start;
      justify-content: center;
      .circle-title {
        font-size: 1.2vw;
        font-weight: 800;
        margin-bottom: 1vw;
        @media screen and (max-width: 575px) {
          font-size: 2.4vw;
          margin-bottom: 1.8vw;
        }

        @media screen and (min-width: $largeSize) {
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
      .circle-info {
        font-size: 1.1vw;
        font-weight: 500;

        @media screen and (max-width: 575px) {
          font-size: 2.1vw;
        }

        @media screen and (min-width: $largeSize) {
          font-size: 16px;
        }
      }
    }
    .circle-1 {
      background-image: url('../../assets/circle-1.svg');
      width: 32.6vw;
      height: 14.2vw;
      right: 10vw;
      top: -4vw;
      padding: 0 2vw 2vw 9vw;
      @media screen and (max-width: 575px) {
        width: 61.6vw;
        height: 26.6vw;
        padding: 0 1vw 2vw 15vw;
        right: unset;
        left: 0;
        top: 36vw;
      }
      @media screen and (min-width: $largeSize) {
        width: 457px;
        height: 199px;
        padding: 0 50px 20px 100px;
        top: -50px;
        right: 150px;
      }
    }
    .circle-2 {
      background-image: url('../../assets/circle-2.svg');
      width: 38vw;
      height: 36vw;
      top: 10vw;
      left: 0vw;
      padding: 0 8vw 2vw 9vw;

      @media screen and (max-width: 575px) {
        width: 72vw;
        height: 72vw;
        padding: 0 10vw 0vw 16vw;
        left: unset;
        right: -6vw;
        top: 62vw;
      }

      @media screen and (min-width: $largeSize) {
        width: 500px;
        height: 500px;
        padding: 0 80px 0 130px;
        top: 150px;
      }
    }
    .circle-3 {
      background-image: url('../../assets/circle-3.svg');
      width: 39vw;
      height: 27.5vw;
      bottom: -10vw;
      right: 3vw;
      padding: 0 4vw 2vw 6vw;
      @media screen and (max-width: 575px) {
        width: 76vw;
        height: 51.7vw;
        padding: 0 8vw 2vw 8vw;
        right: unset;
        left: 0;
        bottom: -30vw;
      }

      @media screen and (min-width: $largeSize) {
        width: 550px;
        height: 385px;
        padding: 0 80px 0 80px;
        bottom: -150px;
        right: 50px;
      }
    }
  }
}
