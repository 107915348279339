@import '../../variables';

section.second {
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .section-body {
        height: 38.5vw;
        background: $yellowColor;
        padding: 0 0 0 9.4vw;
        display: flex;
        align-items: center;
        width: 100%;
    
        @media screen and (max-width: 575px) {
            height: fit-content;
            padding: 0 5.8vw;
            flex-direction: column;
        }
        @media screen and (min-width: $largeSize) {
            padding: 45px 40px 40px 40px;
            max-width: 1400px;
            position: relative;
            height: fit-content;
        }
    }
    .section-top {
        width: 100%;
        transform: translateY(.1vw);
        height: 20vw;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../assets/waves-2.svg');
        @media screen and (max-width: 575px) {
            background-image: url('../../assets/waves-2_mobile.svg');
            height: 54vw;
        }
        @media screen and (min-width: $largeSize) {
            max-width: 1400px;
            height: 240px;
        }
    }
    .section-bottom {
        transform: translateY(-.1vw);
        width: 100%;
        height: 19.2vw;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../assets/waves-3.svg');
        @media screen and (max-width: 575px) {
            background-image: url('../../assets/waves-3_mobile.svg');
            height: 44vw;
        }
        @media screen and (min-width: $largeSize) {
            max-width: 1400px;
            height: 208px;
        }
    }
    .section-body__left{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        @media screen and (min-width: $largeSize) {
            margin-left: 90px;
            max-width: 780px;
        }
        a {
            color: black;
            text-decoration: underline;
        }
        h3 {
            font-size: 2vw;
            text-align: start;
            @media screen and (max-width: 575px) {
                font-size: 5.3vw;
            }
            @media screen and (min-width: $largeSize) {
                font-size: 28px;
            }
        }
        p {
            text-align: start;
            font-size: 1.1vw;
            max-width: 59.2vw;
            font-weight: 500;
            @media screen and (max-width: 575px) {
                font-size: 3.4vw;
                max-width: unset;
            }
            @media screen and (min-width: $largeSize) {
                font-size: 16px;
            }
            &.p-strong {
                margin: 2.1vw 0;
                font-size: 1.4vw;
                font-weight: 800;
                width: 38.7vw;
                
                @media screen and (max-width: 575px) {
                    width: 100%;
                    font-size: 3.4vw;
                    margin: 6.6vw 0;
                }
                
                @media screen and (min-width: $largeSize) {
                    width: 650px;
                    font-size: 20px;
                    margin: 30px 0;
                }
            }
        }
        .info-box {
            margin-top: 2.1vw;
            img {
                height: 3.2vw;
                width: 3.2vw;

            }
            @media screen and (max-width: 575px) {
                margin-top: 6.6vw;
                img {
                    height: 7.2vw;
                    width: 7.2vw;

                }
            }
            @media screen and (min-width: $largeSize) {
                margin-top: 30px;
                img {
                    height: 46px;
                    width: 46px;

                }
            }
        }
    }
    .section-body__right {
        @media screen and (max-width: 575px) {
            // padding-bottom: 40vw;
        }
        .girl-woman {
            position: absolute;
            bottom: 8vw;
            right: 2vw;
            width: 30vw;
            height: auto;
            z-index: 5;
            @media screen and (max-width: 575px) {
                position: initial;
                width: 53vw;
                margin-top: 5vw;
            }
            @media screen and (min-width: $largeSize) {
                width: 426px;
                bottom: -150px;
                right: 60px;
            }
        }
        .section-body__right-text {
            position: absolute;
            top: 15vw;
            right: 0;
            margin-right: 5.7vw;
            width: 36.7vw;
            display: none;
            
            @media screen and (max-width: 575px) {
                width: 100%;
                margin-right: 0;
                position: initial;
            }
            @media screen and (min-width: $largeSize) {
                width: 515px;
                top: -60px;
                margin-right: 64px;
            }
            h4 {
                text-align: start;
                font-size: 1.4vw;
                
                @media screen and (max-width: 575px) {
                    font-size: 3.4vw;
                    margin-top: 9.3vw;
                    margin-bottom: 3.4vw;
                }
                @media screen and (min-width: $largeSize) {
                    font-size: 20px;
                }
            }
            ul {
                margin-left: 2vw;
                margin-top: 1.07vw;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                
                @media screen and (max-width: 575px) {
                    margin-left: 5vw;
                    margin-top: 0;
                }
                @media screen and (min-width: $largeSize) {
                    margin-top: 24px;
                    margin-left: 24px;
                }
                li {
                    &::marker {
                        font-size: 1vw;
                        
                        @media screen and (max-width: 575px) {
                            font-size: 3.4vw;
                        }
                        @media screen and (min-width: $largeSize) {
                            font-size: 16px;
                        }
                    }
                    
                    @media screen and (max-width: 575px) {
                        font-size: 3.4vw;
                    }
                    @media screen and (min-width: $largeSize) {
                        font-size: 16px;
                    }
                    text-align: start;
                    font-size: 1.1vw;
                    list-style:disc;
                }
            }
        }
    }
}

.info-box {
    padding: 1.4vw 2.1vw;
    border-radius: 1.2vw;
    background: white;
    display: flex;
    align-items: center;
    max-width: 44.5vw;
    font-size: 1.1vw;
    @media screen and (max-width: 575px) {
        padding: 4.3vw 10.4vw 4.3vw 4.5vw;
        max-width: none;
        font-size: 3.4vw;
        border-radius: 2.6vw;
    }
    @media screen and (min-width: $largeSize) {
        padding: 16px 50px 16px 30px;
        font-size: 16px;
        border-radius: 18px;
    }
    img {
        width: 1.85vw;
        height: 1.85vw;
        margin-right: 1.5vw;
        @media screen and (max-width: 575px) {
            width: 6.1vw;
            height: 6.1vw;
            margin-right: 3.7vw;
        }
        @media screen and (min-width: $largeSize) {
            width: 26px;
            height: 26px;
            margin-right: 21px;
        }
    }
}